import React from "react";
import Footer from "./Footer"

export default function Catering() {
    return (
        <>
          <div className="bg-white px-2 py-24 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-4xl text-center">
              <h2 id="rust" className="mt-28 text-5xl tracking-tight text-gray-900 sm:text-6xl">Catering Request</h2>
              <div className="mt-6 mb-4 py-6 sm:px-8 md:grid md:grid-cols-3	grid sm:grid-none    ">
          <a
              href="/BeverageMenu" target="_blank"
              className="rounded-md m-4 mx-8 bg-beige px-3.5 py-2.5 text-m font-bold text-white shadow-sm hover:bg-black lg: transition duration-700 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Beverage Menu
            </a>
            <a
              href="/SampleMenus" target="_blank"
              className="rounded-md m-4 mx-8 md:mx-2 bg-blue px-3.5 py-2.5 text-m font-bold text-white shadow-sm hover:bg-black transition duration-700 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Sample Menus
            </a>
            
            <a
              href="/AppetizerMenu" target="_blank"
              className="rounded-md m-4 mx-8 bg-red px-3.5 py-2.5 text-m font-bold text-white shadow-sm hover:bg-black transition duration-700 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Appetizer Menu
            </a>
          </div>
              <p className=" lg:text-xl text-lg leading-6 text-gray-600 text-center  line-normal  lg:px-none lg:text-center px-4 ">
              Chef Jason and his team insist on utilizing the best ingredients to make memorable delicious meals & menus for each event. He will always include a personal conversation and consultation with you about the event that you have in mind.</p>




              <p className="mt-6 -mb-2 lg:text-xl text-lg leading-6 text-center  text-gray-600  lg:text-center px-4">
                This form will give us a feel for the logistical parts of any event we might get to do with you.
              </p>
            </div>
            </div>
            
            <div class="flex items-center justify-center -mt-16 mb-5">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSegyn1XZCjc_hchh2BfbwWEF82yGZ81bOMuhG6iHOcZVpDYWg/viewform?embedded=true" width="640" height="3361" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> 
          </div>

        </>
    )
}