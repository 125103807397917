import React from "react";
import nmmain from '../nmmain.png'
import nm1 from '../nm1.png'
import Banner from './Banner.js'
import AboutSection from './AboutSection.js'
import CateringForm from './CateringForm'
import Footer from './Footer'
import '../App.css'
import Rust from '../fonts/IntroRustBase.ttf'
import EventsBanner from "./EventsBanner";
import ContactSnip from "./ContactSnip";
import sihb from "../sihb.png"
import kraken from '../kraken.png'
import UW from "../UW.png"
import meta1 from "../meta1.png"
import mckshoot from "../mckshoot.png"
import DishFeature from "./DishFeature.js"
import ThinBanner from "./ThinBanner.js"
import summer from '../SummerMenu1.png'
import Photogal from "./Photogal.js";

import plate1 from '../plate1.jpg'
import summer2 from '../SummerMenu.png'

import beef from '../BeefMenu.png'
import chicken from '../ChickenMenu.png'
import salmon from '../SalmonMenu.png'
import Hero from './Hero.js'

export default function Home () {

    return (
        <div class=" ">

<div id="home" className="relative isolate overflow-hidden pt-12 md:pt-32">
  <img
    src={plate1}
    alt="Photo of food made by Jason A Vickers"
    className="absolute inset-0 -z-10 h-full w-full object-cover brightness-50"
  />

  <div className="relative mx-auto max-w-5xl px-4 py-20 mt-20 text-center lg:py-32">
    <div
      id="home-text"
      className="flex flex-col items-center justify-center space-y-8 lg:flex-row lg:space-y-0 lg:space-x-8"
    >
      <div className="flex-1">
        <h1
          id="rust"
          className="text-5xl  tracking-tight text-white md:text-6xl lg:text-left"
        >
          Catering for Events with a Traditional Indigenous Twist
        </h1>
      </div>

      <div className="flex-1">
        <p
          id="white"
          className="rounded-lg bg-black bg-opacity-60 p-5 text-lg leading-8 text-white md:text-xl lg:text-left"
        >
          Chef Vickers includes the incredible bounty of traditional foods,
          knowledge, and foodways Native Country has to offer to present
          beautifully curated meals, catered events, classes, and stories as
          Natoncks Metsu.
        </p>
      </div>
    </div>

    {/* Buttons Section */}
    <div className="mt-16 flex justify-center gap-x-4">
      <a
        href="/Catering"
        target="_blank"
        className="rounded-md bg-red px-3.5 py-2.5 text-sm font-bold text-white shadow-sm transition duration-700 hover:bg-black hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
      >
        Catering Request
      </a>
      <a
        href="/Meal-Prep"
        target="_blank"
        className="rounded-md border-red bg-white px-4 py-2.5 text-sm font-bold text-red shadow-sm transition duration-700 hover:bg-black hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
      >
        Meal Prep
      </a>
    </div>
  </div>
</div>



    <div class="bg-[#1d1d1d] ">
    
    <div id="height" class="  text-center   ">
      <p class="text-white  pt-20  mb-3 text-xl italic"><span class="text-red">-</span> Downloadable <span class="text-red">-</span></p>
    <h2 id="rust" className=" text-white md:text-6xl tracking-tight text-gray-900 text-4xl">Sample Menus</h2>
    <div class="grid md:grid-cols-4 grid-cols-1   py-4 ">

            <div class="mb-10">
         
            <img
                  src={beef}
                  alt="Beef Menu"
                  className="object-contain lg:px-0 lg:w-3/4 mb-8   justify-center m-auto mt-12 px-8"
                />    

            <a
              href="BeefMenu.pdf" target="_blank"
              className="rounded-md m-4 mx-8 md:mx-2 bg-blue px-3.5 py-2.5  lg:text-lg  md:text-sm sm:text-md font-bold text-white shadow-sm hover:bg-red transition duration-700 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
          
              Download Beef Menu
            </a>
            </div>    
            
            <div class="mb-10">
            
            <img
                  src={chicken}
                  alt="Chicken Menu"
                  className="object-contain lg:w-3/4 mb-8 md:px-8 lg:px-0   justify-center m-auto mt-12 px-8 "
                />  

            <a
              href="ChickenMenu.pdf" target="_blank"
              className="rounded-md m-4 mx-8  md:mx-2 bg-blue px-3.5 py-2.5  lg:text-lg  md:text-sm sm:text-md font-bold text-white shadow-sm hover:bg-red transition duration-700 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
            
              Download Chicken Menu 
                         </a>
            </div>
      

            <div class="mb-10">
            
            <img
                  src={salmon}
                  alt="Summer Sample Menu"
                  className="object-contain lg:w-3/4 mb-8 md:px-8 lg:px-0  justify-center m-auto mt-12 px-8 "
                />  
            <a
              href="SalmonMenu.pdf" target="_blank"
              className="rounded-md m-4 mx-8 nowrap md:mx-2 bg-blue px-3.5 py-2.5  lg:text-lg  md:text-sm sm:text-md font-bold text-white shadow-sm hover:bg-red transition duration-700 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            > 

           
              Download Salmon Menu 
                         </a>
            </div>


            <div class="mb-10">
            
            <img
                  src={summer2}
                  alt="Summer Sample Menu"
                  className="object-contain lg:w-3/4 mb-8 md:px-8 lg:px-0   justify-center m-auto mt-12 px-8 "
                />  

            <a
              href="SummerSampleMenu2.pdf" target="_blank"
              className="rounded-md m-4 mx-8  md:mx-2 bg-blue px-3.5 py-2.5 lg:text-lg  md:text-sm sm:text-md font-bold text-white shadow-sm hover:bg-red transition duration-700 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
           
              Download Multi-Menu
                         </a>
            </div>
        
        </div>
        
        


        </div>
        
        



    </div>



    
        
        <EventsBanner />

        <div id="marginstuff">
          
        <AboutSection />

        </div>
        
        </div>
    )
}




/* <h1 className="italic text-center p-3 text-xl mt-6">Catered events for</h1>

    <div id="redblock" className="bg-red"></div>

        <div id="business" className="grid lg:grid-cols-5  sm:grid-cols-2 gap-8 p-7 mb-3 mt-8 ">
            <a href="https://www.sihb.org/" target="_blank" rel="noreferrer"><div><img src={sihb} className="m-auto hover:scale-125 transition duration-500" alt="Seattle Indian Health Board Logo" /><p className="text-center p-4">Seattle Indian<br></br>Health Board</p></div></a>

            <a href="https://www.nhl.com/kraken/" target="_blank" rel="noreferrer"><div><img src={kraken} className="m-auto hover:scale-125 transition duration-500"  alt="Seattle Kraken Logo"  /><p className="text-center p-4">Seattle Kraken</p></div></a>
            <a href="https://www.washington.edu/" target="_blank" rel="noreferrer"> <div><img src={UW} className="m-auto hover:scale-125 transition duration-500" alt="University of Washington Logo" /><p className="text-center p-4">University of Washington</p></div></a>
            <a href="https://www.muckleshoot.nsn.us/" target="_blank" rel="noreferrer"><div><img src={mckshoot} className="m-auto hover:scale-125 transition duration-500" alt="Muckleshoot Indian Health tribe" /><p className="text-center p-4">Muckleshoot Indian<br></br>Health Tribe</p></div></a>   
            <a href="https://about.meta.com/" target="_blank" rel="noreferrer"><div><img src={meta1} className="m-auto hover:scale-125 transition duration-500" alt="Meta Logo" /><p className="text-center p-4">Meta</p></div></a>
       


        </div>

*/