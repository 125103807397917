
import gen from "../8gen.jpg"



//4d6eee58-b87b-4a0d-b252-bc6038999dfc
export default function Press () {





    const articles = [
     
      {
        date: "December 31, 2024",
        company: "Native America Calling",
        title: "The Menu: King Philip Corn, the healing power of Natoncks Metsu, and remembering Terrol Dew Johnson",
        preview: 
        "Jason Vickers (Nipmuc) just opened his own catering and personal chef business in Seattle. Natoncks Metsu is the culmination of his connection to food sovereignty that helped him overcome substance abuse and homelessness.",
        link: "https://www.nativeamericacalling.com/tuesday-december-31-2024-he-menu-king-philip-corn-the-healing-power-of-natoncks-metsu-and-remembering-terrol-dew-johnson/"
    },
      {
        
        date: "November 25, 2024",
        company: "Seattle Magazine",
        title: "Chef Overcomes Personal Troubles to Celebrate his Native American Roots",
        preview: 
        "Jason Vickers is as Seattle food and culture as you get. Yet, many have never heard of him. He has cooked at numerous iconic places across the region with chefs such as Kathy Casey, Russell Lowell, Jonathan Hunt, Tom Black, Scott Staples and the late Tamara Murphy. He recently founded Netoncks Metsu, the catering, meal prep and special events business dedicated to cooking with historically local ingredients and First Nation’s traditions.",
        link: "https://seattlemag.com/food-drink/chef-overcomes-personal-troubles-to-celebrate-his-native-american-roots/"
    },
      {
        
        date: "November 11, 2024",
        company: "Converge",
        title: "Eighth Generation and Huy: Wrapping a Community in Support and Second Chances",
        preview: 
        "A powerful and moving event recently unfolded in Seattle, celebrating the release of the Huy/Eighth Generation/Louie Gong butterfly blanket, a poignant symbol of transformation and hope for Indigenous people impacted by incarceration.",
        link: "https://www.whereweconverge.com/post/eighth-generation-and-huy-wrapping-a-community-in-support-and-second-chances"
    },
      {
        
          date: "November 18, 2023",
          company: "South Seattle Emerald",
          title: "Jason Vickers' Indigenous takeover of Tilth Alliance's Community Kitchen Brought Native Food to the Table",
          preview: 
          "On a recent drippy Sunday afternoon, dozens of Seattleites made their way from the street onto the bouncy soil of the luscious Rainier Beach Urban Farm and Wetlands. As people sipped water infused with salad burnet (an herbaceous plant that tastes exactly like cucumber) and hot tea, the congregants settled around sheltered tables outside — they came to eat, listen, and learn.",
          link: "https://southseattleemerald.com/2023/11/18/jason-vickers-indigenous-takeover-of-tilth-alliances-community-kitchen-brought-native-food-to-the-table/"
      },
      {
        date: "October 9, 2023",
        company: "Seattle Times",
        title: "Seattle celebrates its second official Indigenous Peoples’ Day",
        preview:
          "Honoring our elders: Past and Present. That was the theme Monday as hundreds gathered at Westlake Park to march through downtown Seattle in celebration of Indigenous Peoples' Day. Monday marked the second time the city of Seattle officially celebrated the holiday, which 10 states and more than 100 cities across the country have adopted in lieu of Columbus Day.",
        link: "https://www.seattletimes.com/seattle-news/seattle-celebrates-its-second-official-indigenous-peoples-day/"
      }
    
    ]
      
      const events = [

    ] 
      



    const pastEvents = [
      {
        date: "June 01, 2024",
        image: "https://seattlepride.org/web/app/templates/assets/image/03150257-446F-4DDD-9062-211E501FB6F9_2024-04-24-222817_sjos.png",
        time: "All day!",
        title: "Natoncks Booth at Seattle Pride 50",
        host: "Seattle Pride",
        hostlink: "https://seattlepride.org/",
        location: 
        "10601 5th Ave NE, Seattle, WA 98125",
        gps: "https://maps.app.goo.gl/5DUJhN4u5e3WUQv8A",
        link: "https://seattlepride.org/pages/50th-anniversary"
      },
      {
        date: "December 9, 2023",
        image: "https://media.d3.nhle.com/image/private/t_ratio16_9-size30/f_auto/prd/vyx4tfzrus9jgtztbgrg.jpg",
        time: "7:00 pm - 10:00 pm",
        title: "Indigenous Peoples Night Pres. By Muckleshoot Indian Tribe",
        host: "Seattle Kraken",
        hostlink: "https://www.nhl.com/kraken/",
        location: 
        "10601 5th Ave NE, Seattle, WA 98125",
        gps: "https://maps.app.goo.gl/5DUJhN4u5e3WUQv8A",
        link: "https://www.nhl.com/kraken/community/specialty-jerseys"
      },
      {
        date: "December 2, 2023",
        image: "https://eighthgeneration.com/cdn/shop/files/DSC_0860_Horiz_d82b0645-b253-4d7c-985c-ac82e8a820df_1200x1200.jpg?v=1614310905",
        time: "2:00 pm - 5:00 pm",
        title: "Sip & Shop",
        host: "8th Generation",
        hostlink: "https://eighthgeneration.com/?gad_source=1&gclid=CjwKCAiApaarBhB7EiwAYiMwqmEIQHS4bMlQjC2NM4HXlzHKMDIOBHNxebPOpp_SCFUof9dPiMx9lxoCDjcQAvD_BwE",
        location: 
        "1406 1st Ave, Seattle, WA 98101-2004, United States",
        gps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2689.9205772895657!2d-122.339093!3d47.60823399999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54906ab24efa7bbb%3A0xfcb994381403d533!2s1406%201st%20Ave%2C%20Seattle%2C%20WA%2098101!5e0!3m2!1sen!2sus!4v1701459719550!5m2!1sen!2sus",
        link: "https://www.facebook.com/events/s/sip-shop/1364545814193495/"
      },

      {
      date: "November 12, 2023",
      image: "https://tilthalliance.org/wp-content/uploads/2023/10/jason-vickers.jpg",
      time: "1:00 pm - 3:00 pm",
      title: "Community Kitchen Meals: Indigenous Takeover with Natoncks Metsu",
      host: "Tilth Alliance",
      hostlink: "https://tilthalliance.org/",
      location: 
      "Rainier Beach Urban Farm and Wetlands, 5513 S Cloverdale St, Seattle, WA 98118",
      gps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2694.149976954126!2d-122.26487262324804!3d47.525943071182574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54904274d6dd70d1%3A0x2ddb0b3322226bf5!2sRainier%20Beach%20Urban%20Farm%20and%20Wetlands!5e0!3m2!1sen!2sus!4v1697759380348!5m2!1sen!2sus",
      link: "https://tilthalliance.org/event/community-kitchen-meals-indigenous-takeover-with-natoncks-metsu/"
    }
    
  ] 
    







  
      return (
  
          <>
          <div id="mpheader" className="bg-white px-6 py-24 sm:py-32 lg:px-8" >
            <div className="mx-auto max-w-2xl text-center lg:mb-4 md:mb-10">
              <h2 id="rust" className="mt-6 mb-8 text-4xl tracking-tight text-gray-900 sm:text-6xl">Events & Press</h2>
  
            </div>
          </div>

          <div class="grid lg:grid-cols-2 gap-4">


          <div className="bg-white mb-12">

              <div className="mx-auto max-w-7xl  px-6 py-18 sm:py-22 lg:px-8 lg:py-12">
              <h2 className=" font-bold text-2xl">Events</h2>
              <p className="mt-10">No upcoming events, check back later</p>

              <dl className="mt-6 space-y-8">

             
             {events.map((event) => (
              
               <div key={event.date} className="pt-8">
                   <p className="text-base leading-7 font-bold text-red">

                     {event.date} / {event.time}</p>

                     <a href={event.link} target="_blank" rel="noreferrer">
                   <img src={event.image} alt="Event Image" width="auto" className="rounded  py-4"></img>
                     <a href={event.hostlink} target="_blank" rel="noreferrer"><p className="text-base leading-7 py-2 italic font-semibold">{event.host}</p></a>

                      <h3 className="text-2xl font-semibold leading-7 underline text-red hover:text-black">{event.title}</h3></a>
                       <p className="text-base leading-7 text-gray-600 flex display:inline py-4">
                       <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 20" strokeWidth={1.5} stroke="red" className="w-6 h-6 p-1">
                           <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                           <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                         </svg>

                         {event.location}

                       </p>

                   <hr className="mt-10 red"></hr>
                   
               </div>
             ))}

           </dl>





                
              
                <h2 className=" font-bold text-2xl mt-20 text-red">Archived Events</h2>

                <dl className="mt-6 space-y-8">
             
                  {pastEvents.map((pastEvent) => (
                    <div key={pastEvent.date} className="pt-8">
                        <p className="text-base leading-7 font-bold text-red">

                          {pastEvent.date} / {pastEvent.time}</p>
                          
                          <a href={pastEvent.link} target="_blank" rel="noreferrer">
                        <img src={pastEvent.image} alt="Jason Vickers at Event" className="rounded w-auto py-4"></img>
                          <a href={pastEvent.hostlink} target="_blank" rel="noreferrer"><p className="text-base leading-7 py-2 italic font-semibold">{pastEvent.host}</p></a>

                           <h3 className="text-2xl font-semibold leading-7 underline text-red hover:text-black">{pastEvent.title}</h3></a>
                            <p className="text-base leading-7 text-gray-600 flex display:inline py-4">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 20" strokeWidth={1.5} stroke="red" className="w-6 h-6 p-1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                              </svg>

                              {pastEvent.location}

                            </p>

                        <hr className="mt-10 red"></hr>
                        
                    </div>
                  ))}

                </dl>

              </div>

              
            </div>
        
  
        <div className="bg-white">
              <div className="mx-auto max-w-7xl  px-6 py-18 sm:py-22 lg:px-8 lg:py-12">

              <h2 className=" font-bold text-2xl">Press</h2>

                <dl className="mt-6 space-y-8">
                  {articles.map((article) => (
                    <div key={article.id} className="pt-8">
                        <p className="text-base leading-7 text-red font-bold">{article.date} - {article.company}</p>
                           <a href={article.link} target="_blank" rel="noreferrer"><h3 className="text-2xl font-semibold leading-7 text-red py-4 hover:text-black underline">{article.title}</h3></a>
                        <p className="text-base leading-7 text-gray-600">{article.preview}</p>
                        <a href="https://www.seattletimes.com/seattle-news/seattle-celebrates-its-second-official-indigenous-peoples-day/" target="_blank" rel="noreferrer"><h3 className="text-base leading-7 text-red font-bold mt-4 hover:text-black"><button>Read article</button></h3></a>
                        <hr className="mt-10 red"></hr>
                        
                    </div>
                  ))}
                </dl>

                <h2 className=" font-bold text-2xl mt-10">Podcast Episodes</h2>
                <iframe id="podcast" allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write" frameborder="0" height="200" class="mt-10" title="Podcast Feat. Jason A. Vickers" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.podcasts.apple.com/us/podcast/reclaiming-our-stories-part-1-native-voices-on-recovery/id1697044420?i=1000634604601"></iframe>

              </div>
              

            </div>

 

</div>
  
  </>
      )
  }
   

  // <p className="mt-10">No upcoming events, check back later</p>