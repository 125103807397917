import Hero from './Hero'



export default function Product () {

    return (

        <div>
     <div className="bg-[#1d1d1d]  py-24 sm:py-32 ">
            <div className="mx-auto  text-center">
              <h2 id="rust" className="mt-28 text-5xl tracking-tight text-white sm:text-6xl">Products</h2>
             
              <p className="mt-8 py- text-lg leading-8 text-white text-center lg:text-xl line-normal  lg:px-none lg:text-center px-4 ">
                *Limited Edition December 2024 - More to come in 2025!
              </p>

            <Hero />



         
            </div>
            </div>
            
        



        </div>




    )

}