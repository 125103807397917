import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import GiftBox from '../GiftBox5.png'
import BoxFaq from './BoxFaq'
import side1 from "../Side1.png"
import side2 from '../Side2.png'


export default function Hero() {
  return (

    <div class="bg-[#1d1d1d] min-h-screen flex flex-col items-center py-20 mt- px-4 sm:px-6 lg:px-12">
    <h1 id="rust" class="text-3xl lg:text-5xl text-white text-center max-w-3xl">
    100% Native Made Gift Box</h1>

    <div class="mt-12 flex flex-col lg:flex-row lg:items-center lg:justify-between w-full max-w-7xl space-y-8 lg:space-y-0 lg:space-x-8">
        <div class="lg:flex-1 text-center lg:text-left text-white text-lg">
        
          <h2 id="rust" class="mb-4 text-xl text-beige">What's in the Box:</h2>

          <ul class="">
              <li class="text-white">Elderberry Wellness Tonic</li>
              <li>Woodland Spice Blend</li>
              <li>Pepita Brittle</li>
              <li>Wild Rice Crackers</li>
              <li>Elderberry Truffles</li>
              <li>
                  Flight of Soda Syrups:<br></br>
                  <span class="italic text-beige">"Lumminade"</span> (Rosemary, Mint & Lemon),<br></br>
                  <span class="italic text-beige">"Indigie Koolaid"</span> (Hibiscus & Lime),<br></br>
                  <span class="italic text-beige">"Salish Style"</span> (Cedar & Tangerine)
              </li>            
              <li>Seasoned & Canned Salmon</li>
              <li>Rosehip Apple Butter</li>
              <li>Blue Corn Sablé Cookies</li>



          </ul>
      
        </div>

        <div class="lg:flex-shrink-0 flex justify-center">
        <img 
            src={GiftBox}
            alt="Gift Box" 
            class=" max-w-full sm:max-w-md lg:max-w-md"
        />
        </div>

        <div class="lg:flex-1 text-center lg:text-right text-white lg:text-xl text-lg lg:leading-9">
        <p class="bg-gray-800 bg-opacity-80  rounded-lg ">
        Years of collaboration and community partnership between<br></br>
        <span id="rust" class=" text-beige">Chef Jason Vickers (Nipmuc)</span> of Natoncks Metsu <span class="italic">"Feeding my Cousins"</span> and  
        <span id="rust" class=" text-beige"> Chef Shawn Peterson (Nuu-chah-nulth)</span>, of Haw̓akstup  <span class="italic">"Feed and Nourish"</span> is bearing beautiful fruit this holiday season in the form of delightful gift boxes, featuring handmade indigenous treats.
      </p>
        </div>
    </div>

    

    <div className="flex flex-col md:flex-row items-center justify-center gap-14 p-4 mt-20">
      <img
        src={side1}
        alt="Image 1"
        className="w-full md:w-2/5 rounded shadow-lg"
      />
      <img
        src={side2}
        alt="Image 2"
        className="w-full md:w-2/5 rounded shadow-lg"
      />
    </div>

    
    </div>


  
  )
}


/*<span class="material-symbols-outlined text-5xl text-white mt-8 animate-bounce">
    keyboard_double_arrow_down
    </span> 
    
    
    
    
    <div class="mt-16">
        <a 
        href="https://square.link/u/XWFbimmT"  target="_blank" rel="nonreferrer"
        class="px-8 py-3 bg-red text-white text-lg font-medium rounded-lg  hover:bg-white hover:text-black focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2  transition duration-700"
        >
        Buy Now
        </a>
    </div>*/